@import 'shared-assets/src/assets/styles/mixins';
@import 'shared-assets/src/assets/styles/variables';
@import 'shared-assets-purposeful/src/assets/styles/variables';

body {
  font-family: $nunitoStack;
  color: var(--onBackground);
  background-color: var(--primary);
}

a {
  color: var(--primary);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--onBackground);
}

svg {
  color: var(--onBackground);
  fill: var(--onBackground);
}

svg:where(svg:not([fill])) {
  fill: currentColor;
}

.product-logo {
  width: 141px;
  height: 33px;
  fill: $nighttimePrimaryColorHex;
  color: $nighttimePrimaryColorHex;
}

select {
  @include body-font;
  color: var(--onBackground);
  border: 1px solid var(--primary);
  background-color: var(--primary);
}

input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'] {
  height: 40px;
  background-color: $black15;
  color: var(--onBackground);
  border: none;
  border-bottom: 2px solid var(--onBackground);
}

//noinspection CssInvalidPseudoSelector; required cause PHPStorm is not correctly picking up that this is valid
input:autofill,
input:-webkit-autofill {
  /* eliminate transition for inputs that can be autofilled */
  animation: autofill 0s forwards;
}

@keyframes autofill {
  to {
    color: var(--onBackground);
    background: $black15;
  }
}

/* Button style overrides from global */
.button {
  color: var(--primary);
  background-color: var(--onBackground);

  &:hover,
  &:focus {
    background-color: var(--onBackground80);
  }

  &[disabled],
  &.disabled {
    background-color: var(--onBackground30);
    color: var(--onBackground50);
  }
}

.button-reverse {
  background-color: var(--primary);
  color: var(--onBackground);

  &:hover,
  &:focus {
    background-color: var(--primary70);
  }

  &[disabled],
  &.disabled {
    background-color: var(--primary30);
    color: var(--onBackground80);
    border: 1px solid var(--transparent);
  }
}

.button-secondary {
  color: var(--onBackground);
  background-color: var(--transparent);
  border: 1px solid var(--onBackground);

  &:hover,
  &:focus {
    background-color: var(--onBackground80);
    color: var(--primary);
    border: 1px solid var(--transparent);
  }

  &[disabled],
  &.disabled {
    background-color: var(--onBackground30);
    color: var(--onBackground50);
    border: 1px solid var(--transparent);
  }
}

.button-secondary-reverse {
  color: var(--primary);
  background-color: var(--transparent);
  border: 1px solid var(--primary);

  &:hover,
  &:focus {
    background-color: var(--primary70);
    color: var(--onBackground);
    border: 1px solid var(--transparent);
  }

  &[disabled],
  &.disabled {
    background-color: var(--primary50);
    color: var(--onBackground80);
    border: 1px solid var(--primary);
  }
}

.button-opaque-border {
  background-color: var(--onBackground);
  color: var(--primary);
  border: 1px solid var(--primary);

  &:hover,
  &:focus {
    background-color: var(--primary70);
    color: var(--onBackground);
    border: 1px solid var(--transparent);
  }

  &[disabled],
  &.disabled {
    background-color: var(--primary50);
    color: var(--onBackground80);
    border: 1px solid var(--primary);
  }
}

/* need to copy from global to override .button color overrides */
.button-transparent-black {
  background-color: $black40;
  color: $white;

  &:hover,
  &:focus {
    background-color: $black70;
  }

  svg {
    color: $white;
    fill: $white;
  }
}
