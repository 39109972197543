@import '../variables';

.tippy-box[data-theme~='challenge-menu'] {
  background-color: var(--onBackground);
  background-clip: padding-box;
  border-radius: 8px;
  border: none;
  padding: 4px 12px;
  color: var(--primary);
  box-shadow: 2px 2px 8px 2px rgba(177, 177, 177, 0.5);
}
.tippy-box[data-theme~='challenge-menu'] > .tippy-backdrop {
  background-color: var(--onBackground);
}
[data-theme~='challenge-menu'] > .tippy-content {
  padding: 0;
}
