/**
 Import other style files

 Do not put any CSS except for imports here:
  - Variables (e.g. color, sizing, media queries) go in the variables.scss file
  - Mixins go in the mixins.scss file
  - Theme specific styles go in the theme.scss file
  - Global styles should be minimized, but would go in global.scss file
  - Global print styles should be minimized, but would go in print.scss file
  - All other styles should be in their respective component SCSS file
 */
@import '~normalize.css/normalize.css';
@import '~sanitize.css/sanitize.css';
@import '~sanitize.css/forms.css';

/**
 Import App styles
 */
@import 'shared-assets-purposeful/src/assets/styles/variables';
@import 'shared-assets/src/assets/styles/mixins';
@import 'shared-assets/src/assets/styles/global';

/* Override global styles or add app-specific global styles */
@import 'shared-assets-purposeful/src/assets/styles/global';
@import 'shared-assets-purposeful/src/assets/styles/theme';

/* Styles for tippy and the default tooltip theme(s) */
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/animations/scale.css';
@import 'shared-assets-purposeful/src/assets/styles/tooltips';

/* Print Styles */
@media print {
  @import 'shared-assets/src/assets/styles/print';
}
