@import 'variables';

/* Global Print Styles
    - independent component print styles can go inline with those components
    - print styles may require the use of !important to better override screen styles
      - !important should still only be used when necessary

    - In general, convert PX to EM @ 16px === 1em (base font size === 1em)

    - How many centimeters are there in a pixel?
      - Use CM sparingly, different printers can print in different DPIs
      - Assuming the pixel density is 96 dpi, there are 96 pixels per inch (ppi).
      - We know that 1 inch is equal to 2.54 cm.
      - So there are 96 pixels per 2.54 cm.
      - Than 1 pixel = (2.54 / 96) cm.
      - There are 0.026458333 centimeters in a pixel @ 96 ppi.
      - Adjust as necessary for other DPIs
*/

*,
*:before,
*:after {
  background: 0 0 !important;
}

svg,
svg path {
  color: $grey !important;
  fill: $grey !important;
  stroke: $greyLight !important;
}

.hidden-print {
  /* prevent items from printing */
  display: none !important;
}

/* by default, show external links after the link text, like: "Kumanu ( https://kumanu.com )"
   - override individual links as necessary
*/
a[href*="://"]::after
{
  /* note: prettier is moving the { to the next line :( */
  content: ' (' attr(href) ')';
}
