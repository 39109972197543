@import 'variables';

/* NOTE: Important! Please do NOT import theme.scss into other SCSS files as it will duplicate CSS styles */

/* CSS Variables for the main theme, for reference. They are set via the Theme Service dynamically.
   We cannot have them preset here or there can be flashes of the wrong theme briefly.

  --backgroundImageUrl: BackgroundImageUrl (if set, otherwise falls back to default star field
  --primary: PrimaryColorHex // generally Fonts/items
  --primary10: PrimaryColorHex10
  --primary30: PrimaryColorHex30
  --primary50: PrimaryColorHex50
  --primary70: PrimaryColorHex70
  --primary90: PrimaryColorHex90
  --secondary: SecondaryColorHex
  --onBackground: ColorOnBackground // generally Foreground sitting directly on gradient background
  --onBackground10: ColorOnBackground10
  --onBackground20: ColorOnBackground20
  --onBackground30: ColorOnBackground30
  --onBackground50: ColorOnBackground50
  --onBackground80: ColorOnBackground80
  --onBackground95: ColorOnBackground95
  --gradientColor1: TopColorHex
  --gradientColor2: MiddleColorHex
  --gradientColor3: BottomColorHex
  --iconUrl: IconUrl
  --onBackgroundIconUrl: OnBackgroundIconUrl
*/

:root {
  --transparent: #{$transparent};
}

// NOTE: Fallbacks must be set separately for browsers that may not support fallbacks properly
body {
  background: var(--gradientColor2); /* Old browsers */
}

body {
  background: linear-gradient(
    135deg,
    var(--gradientColor1) 0%,
    var(--gradientColor2) 35%,
    var(--gradientColor3) 100%
  ); /* basically IE11 */
}

/* Good / standard browsers */
body {
  background-position:
    top center,
    0 0;
  background-size:
    auto auto,
    cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: var(--backgroundImageUrl),
    linear-gradient(135deg, var(--gradientColor1) 0%, var(--gradientColor2) 35%, var(--gradientColor3) 100%);
}

@media print {
  // update variables - using !important to override dynamically set variables from theme service
  body {
    --primary: rgba(79, 79, 79, 1) !important;
    --primary05: rgba(79, 79, 79, 0.05) !important;
    --primary10: rgba(79, 79, 79, 0.1) !important;
    --primary30: rgba(79, 79, 79, 0.3) !important;
    --primary50: rgba(79, 79, 79, 0.5) !important;
    --primary70: rgba(79, 79, 79, 0.7) !important;
    --primary90: rgba(79, 79, 79, 0.9) !important;
    --secondary: rgba(40, 40, 40, 1) !important;
    --onBackground: rgba(79, 79, 79, 1) !important;
    --onBackground10: rgba(79, 79, 79, 0.1) !important;
    --onBackground20: rgba(79, 79, 79, 0.2) !important;
    --onBackground30: rgba(79, 79, 79, 0.3) !important;
    --onBackground50: rgba(79, 79, 79, 0.5) !important;
    --onBackground80: rgba(79, 79, 79, 0.8) !important;
    --onBackground95: rgba(79, 79, 79, 0.9) !important;
    --gradientColor1: transparent !important;
    --gradientColor2: transparent !important;
    --gradientColor3: transparent !important;
    --iconUrl: '';
    --transparent: transparent !important;
  }

  body {
    background: none;
  }
}
