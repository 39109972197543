@import 'shared-assets/src/assets/styles/variables';

/* Purposeful specific variables */

/* Specific instances/usage of base colors */
$error: $red;
$onBackgroundError: $white80;
$noData: $blueMed;

/* Theme Colors

   NOTE: in most cases, we should not use theme variables from SCSS;
   Instead, please prefer the CSS Variables (see theme.scss) instead so colors remain dynamic
*/
$daytimePrimaryColorHex: #8e2057;
$nighttimePrimaryColorHex: #202175;
$resourcefulPrimaryColorHex: #215732;

$daytimePrimaryColorHex10: rgba(142, 32, 87, 0.1);
$daytimePrimaryColorHex30: rgba(142, 32, 87, 0.3);
$daytimePrimaryColorHex50: rgba(142, 32, 87, 0.5);
$daytimePrimaryColorHex70: rgba(142, 32, 87, 0.7);

$daytimeTopColorHex: #c85204;
$daytimeMiddleColorHex: #ab392e;
$daytimeMiddleColorHex50: rgba(171, 57, 46, 0.5);
$daytimeBottomColorHex: #8e2057;
$daytimeBottomColorHex50: rgba(142, 32, 87, 0.5);

$nighttimeTopColorHex: #793682;
$nighttimeBottomColorHex: #202175;
$nighttimeBottomColorHex50: rgba(32, 33, 117, 0.5);

$resourcefulHighContrastTopColorHex: #033031;
$resourcefulHighContrastBottomColorHex: #03280c;

$nighttimeGradient: linear-gradient(
  135deg,
  $nighttimeTopColorHex 0%,
  $nighttimeBottomColorHex 35%,
  $nighttimeBottomColorHex 100%
);
$resourcefulHighContrastGradient: linear-gradient(
  135deg,
  $resourcefulHighContrastTopColorHex 0%,
  $resourcefulHighContrastBottomColorHex 35%,
  $resourcefulHighContrastBottomColorHex 100%
);
